<template>
  <div id="cf-turnstile">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
	name: "VueTurnstile",
	props: {
		siteKey: {
			required: true,
		},
		theme: {
			required: false,
			default: "auto",
		},
		size: {
			required: false,
			default: "normal",
		},
		autoReset: {
			required: false,
			default: false,
		},
		resetTimeout: {
			required: false,
			default: 295 * 1000,
		},
		recaptchaCompat: {
			required: false,
			default: false,
		},
		explicitRender: {
			required: false,
			default: false,
		},
		appearance: {
			required: false,
			default: "always",
		},
		language: {
			required: false,
			default: "auto",
		},
	},
	data() {
		return {
			widgetId: null,
		};
	},
	methods: {
		initTurnstile() {
			const script = document.createElement("script");
			const turnstileSrc =
				"https://challenges.cloudflare.com/turnstile/v0/api.js";
			const callback = "onloadTurnstileCallback";
			const compat = this.recaptchaCompat ? "&compat=recaptcha" : "";
			const render = this.explicitRender ? "&render=explicit" : "";

			script.src = `${turnstileSrc}?onload=${callback}${compat}${render}`;
			script.async = true;
			script.defer = true;

			document.head.appendChild(script);
		},
		reset() {
			if (window.turnstile) {
				window.turnstile.reset(this.widgetId);
			}
		},
		remove() {
			if (this.widgetId) {
				window.turnstile.remove(this.widgetId);

				this.widgetId = null;
			}
		},
		render() {
			this.widgetId = window.turnstile.render("#cf-turnstile", {
				sitekey: this.siteKey,
				theme: this.theme,
				size: this.size,
				appearance: this.appearance,
				language: this.language,
				callback: (token) => {
					this.$emit("verified", token);

					if (this.autoReset) {
						setTimeout(() => {
							this.reset();
						}, this.resetTimeout);
					}
				},
				"expired-callback": () => {
					this.reset();
				},
				"error-callback": (error) => {
					console.error(`Error callback: ${error}`);

					this.$emit("error", error);
				},
			});

			this.$emit("rendered");
		},
		onloadTurnstileCallback() {
			window.onloadTurnstileCallback = () => {
				this.render();
			};
		},
	},
	beforeMount() {
		if (window.turnstile === undefined || !window.turnstile) {
			this.initTurnstile();
		}
	},
	mounted() {
		this.$emit("rendering");

		if (window.turnstile === undefined || !window.turnstile) {
			this.onloadTurnstileCallback();
		} else {
			this.render();
		}
	},
	beforeUnmount() {
		this.remove();
	},
};
</script>
